import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Container, Flex, Heading, Text } from "theme-ui";
import { ExternalButtonLink } from "../components/nav-link";

type Props = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

const HearingTestPage = ({ data, location }: Props) => {
  const { html, frontmatter } = data.markdownRemark;
  return (
    <Layout
      activeNav="service"
      seo={{
        title: frontmatter.title,
        pathname: location,
        description: frontmatter.seoDescription,
      }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h1" mb={4}>
          Kostenloser Online Hörtest
        </Heading>
        <Heading as="h2" variant="styles.h3" mb={4}>
          Kostenloser Hörtest: <br /> Online bei OTThören
        </Heading>
        <Text>
          Glauben Sie, dass sich Ihr Gehör verschlechtert hat? Dann ist ein Online-Hörtest die
          ideale Möglichkeit, einen ersten Eindruck über Ihre Hörfähigkeit zu erhalten. Alles was
          Sie dazu benötigen sind Kopfhörer und zehn Minuten Zeit in einer ruhigen Umgebung
        </Text>
        <Flex my={[3, 4, 5]} sx={{ justifyContent: "center" }}>
          <ExternalButtonLink to="https://hearing-screener.beyondhearing.org/udtAHJ">
            OTT hören Online Hörtest starten
          </ExternalButtonLink>
        </Flex>
      </Container>
    </Layout>
  );
};

export default HearingTestPage;

export const pageQuery = graphql`
  query HearingTestPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoDescription
        title
      }
    }
  }
`;
